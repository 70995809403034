/* ------------------------------------------------------------ *\
	Fonts
\* ------------------------------------------------------------ */

@font-face {
    font-family: 'DIN Pro';
    src: url('../fonts/DINPro-CondensedMedium.woff2') format('woff2'),
        url('../fonts/DINPro-CondensedMedium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Aachen';
    src: url('../fonts/AachenBT-Bold.woff2') format('woff2'),
        url('../fonts/AachenBT-Bold.woff') format('woff');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'DIN Pro';
    src: url('../fonts/DINPro-CondensedRegular.woff2') format('woff2'),
        url('../fonts/DINPro-CondensedRegular.woff') format('woff');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'DIN Pro';
    src: url('../fonts/DINPro-CondensedBold.woff2') format('woff2'),
        url('../fonts/DINPro-CondensedBold.woff') format('woff');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}




