/* ------------------------------------------------------------ *\
	Nav Mobile
\* ------------------------------------------------------------ */

.nav-mobile {
	.dropdown {
		position: static;
		background-color: transparent;
		padding: 0;
		opacity: 1;
		visibility: visible;
		font-size: 15px;
		line-height: 2.33;
		letter-spacing: -0.01em;
		font-weight: 300;
		padding-left: 0;
		list-style-type: none;
		transition: none; 

		a {
			position: relative;
			display: block;
			padding-right: 30px;
			color: $black;

			svg {
				position: absolute;
				top: 0;
				right: 0;
				bottom: 0;
				margin: auto;
			}
		} 
	}

	& > ul {
		list-style-type: none;
		padding-left: 0;
		font-size: 20px;
		line-height: 1.2;

		& > li > a {
			position: relative;
			display: block;
			padding-right: 30px;
			color: $black;
			text-transform: uppercase;

			svg {
				position: absolute;
				top: 0;
				right: 0;
				bottom: 0;
				margin: auto;
			}

			&.clicked svg {
				transform: rotate(90deg);
			}
		}

		& > li + li {
			padding-top: 11px;
			border-top: 1px solid #000000;
			margin-top: 11px; 
		}
	}
}
