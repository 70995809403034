/* ------------------------------------------------------------ *\
	Wrapper
\* ------------------------------------------------------------ */

.wrapper {
	padding-top: 113px;
	min-height: 100vh;
	position: relative;
	overflow: hidden;
}

/* === Small Desktop === */
@include media-breakpoint-down(lg) {
	.wrapper {
		padding-top: 73px; 
	}
}

/* === Tablet === */
@include media-breakpoint-down(md) {
	.wrapper {
		padding-top: 75px; 
	}
}
