/* ------------------------------------------------------------ *\
	News Grid
\* ------------------------------------------------------------ */

.news-grid {
	display: flex;
	margin: 0 -10px;

	.news-item-small {
		width: 33.33%;
		padding: 0 10px;
	}
}

.news-item-small {
	&__inner {
		position: relative;
	}

	&__image {
		&:after {
			display: block;
			padding-top: 79.03%;
			content: '';
		}
	}

	@media(min-width: 1025px){
		&__inner:hover .news-item-small__overlay {
			opacity: 1;
			visibility: visible;
			height: 100%;
		}
	}

	&__overlay {
		display: flex;
		align-items: center;
		position: absolute;
		left: 0;
		right: 0;
		bottom: 0;
		padding: 20px;
		background-color: $yellow;
		color: $white;
		height: 0;
		opacity: 0;
		visibility: hidden;
		transition: all .4s;
	}

	&__title {
		font-size: 44px;
		text-transform: uppercase;
		line-height: 1;
	}

	.link {
		color: $white;
		transition: color .4s;
		font-family: $secondary-font;
		font-size: 22px;
		line-height: 1.18;
		font-weight: 500;

		&:hover {
			color: #000;
		}
	}
}

/* === Tablet === */
@include media-breakpoint-down(md) {
	.news-grid {
		display: block;
		margin: 0 0 -15px 0;

		.news-item-small {
			width: 100%;
			padding: 0 20px;
			margin-bottom: 15px;
		}
	}

	.news-item-small {

		&__inner {
			display: flex;
			align-items: center;
		  	a {
			  display: contents;
			}
		}

		&__image {
			width: 50%;
		}

		&__overlay {
			position: static;
			//display: block;
			width: 50%;
			padding: 0 0 0 20px;
			opacity: 1;
			visibility: visible;
			background-color: transparent;
		}

		&__title {
			font-size: 21px;
			line-height: 1;
			color: $black;
		}

		&__link {
			line-height: 1;
		}

		.link {
			font-size: 14px;
			color: $black;
		}
	}
}

/* === Mobile === */
@include media-breakpoint-down(sm) {

  .news-grid {
	display: block;
	margin: 0 0 -15px 0;

	.news-item-small {
	  width: 100%;
	  padding: 0 20px;
	  margin-bottom: 15px;
	}
  }

  .news-item-small {

	&__inner {
	  display: flex;
	  align-items: center;

	  	a {
		  position: relative;
		  display: block;
		  width: 50%;
		  height: 100%;

		}
	}

	&__image {
	  width: 100%;
	}

	&__overlay {
	  position: static;
	  //display: block;
	  width: 50%;
	  padding: 0 0 0 20px;
	  opacity: 1;
	  visibility: visible;
	  background-color: transparent;
	}

	&__title {
	  font-size: 21px;
	  line-height: 1;
	  color: $black;
	}

	&__link {
	  line-height: 1;
	}

	.link {
	  font-size: 14px;
	  color: $black;
	}
  }
}
