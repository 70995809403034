/* ------------------------------------------------------------ *\
	Nav
\* ------------------------------------------------------------ */

.nav {
	& > ul {
		display: flex;
		padding: 0;
		list-style: none;
		font-size: 23px;
		line-height: 1.17;

		& > li > a {
			display: inline-block;
			padding: 44px 32px;
			color: $white;
			transition: all .4s;
		}

		& > li.hover:hover > .dropdown {
			opacity: 1;
			visibility: visible;
		}

		& > li > a.active,
		& > li:hover > a {
			background-color: $yellow;
			color: $black;
		}
	}

	& > ul > li.relative {
		position: relative;

		.dropdown {
			width: 245px;
		}
	}
}

/* === Small Desktop === */
@include media-breakpoint-down(lg) {
	.nav {
		& > ul {
			font-size: 18px;

			& > li > a {
				padding: 26px 15px;
			}
		}
	}
}

/* === Tablet === */
@include media-breakpoint-down(md) {
	.nav {
		display: none;
	}

}
