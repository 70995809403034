/* ------------------------------------------------------------ *\
	Dropdown
\* ------------------------------------------------------------ */

.dropdown {
	position: absolute;
	top: 100%;
	left: 0;
	right: 0;
	z-index: 5;
	padding: 13px 22px 25px;
	background-color: #FFFFFF;
	opacity: 0;
	visibility: hidden;
	transition: all .4s;

	&__categories {
		display: flex;
		margin: 0 -22px;
	}

	& > ul {
		padding-left: 0;
		list-style-type: none;

		li + li {
			margin-top: 13px;
		}

	  	li {
		  margin-top: 12px;
		}

		a {
			color: $black;
			font-size: 20px;
			font-weight: 300;
			letter-spacing: -0.01em;
			line-height: 1.5;
			transition: color .4s;

			&:hover {
				color: #7E7D7D;
			}
		}
	}

	&__category {
		width: 25%;
		padding: 0 22px;
		a {
		  color: black;
		}
	  	a:hover {
		  text-decoration: underline;
		}
		p {
			padding-bottom: 14px;
			border-bottom-width: 4px;
			border-bottom-style: solid;
			margin-bottom: 8px;
			font-size: 21px;
			line-height: 1.17;
			font-weight: 400;
		}

		ul {
			padding-left: 0;
			list-style-type: none;
			font-size: 18px;
			font-weight: 300;
			letter-spacing: -0.01em;
			line-height: 1.4;

			li + li {
				margin-top: 13px;
			}

		  	li {
			  margin-top: 12px;
			}

			a {
				color: #000000;

				&:hover {
					text-decoration: underline;
				}
			}
		}
	}
}
