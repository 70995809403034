/* ------------------------------------------------------------ *\
	Fancy
\* ------------------------------------------------------------ */

.fancy {
	position: relative;
	display: flex;
	align-items: center;

	&__content {
		width: 50%;
		margin-left: auto;
	}

	&__background {
		position: absolute;
		z-index: -1;

		&--second {
			position: static;
			padding: 0 10px;
		  	img {
			  height: 100%;
			}
		}
	}

	&__title {
		margin-bottom: 30px;
		font-family: $primary-font;
		font-weight: 200;

		&--alt {
			font-family: $primary-font;
		}

	  	&__video {
		  	padding-bottom: 10px;
		}
	}

	&__entry {
		font-size: 30px;
		line-height: 1.07;
		letter-spacing: -0.01em;

		a {
			color: $yellow;
			font-size: 39px;
			letter-spacing: -0.01em;
			font-weight: 300;
			line-height: 1.26;
			font-family: $secondary-font;
		}

		p {
			margin-bottom: 1.25em;

			&:last-child {
				margin-bottom: 0;
			}
		}
	}

	&__logo {
		margin-top: 40px;
		max-width: 250px;
		padding-bottom: 60px;

		&__lower {
		  margin-top: 64px;
		  max-height: 130px;
		  img {
			max-height: 120px;
		  }
		}
	}

	.fancy__summary {
		position: relative;
		margin-top: 60px;
		text-align: center;
		font-family: 'Aachen', serif;
	}

	.fancy__front {
		position: relative;
		z-index: 2;
		font-size: 40px;
		line-height: 1.25;
	}

  	.fancy__subtitle {
	  	color: #fbb800;
		font-size: 38px;
		line-height: 1.13;
		letter-spacing: -0.01em;
		font-weight: 300;
	  	padding-bottom: 30px;
	}

	.fancy__back {
		position: absolute;
		z-index: 1;
		top: 50%;
		left: 0;
		right: 0;
		transform: translateY(-50%);
		font-size: 211px;
		line-height: 1;
		color: rgba(0, 0, 0, 0.25);
	}

	&__logos {
		margin-top: 120px;
		&__lower {
		  margin-top: 64px;
		  max-height: 130px;
		  max-width: fit-content;
		  img {
			max-height: 100px;
			height: min-content;
		  }
		}
	}

	&__image {
		width: 59%;
	}

	&__top {
		margin-bottom: 10px;

	  	&__video {
		  padding-bottom: 30px;
		  img {
			max-width: 108px;
		  }
		}
	}

	&__actions {
		margin-top: 55px;

		.btn {
			font-weight: 700;
		}

	  	&__video {
		  padding-top: 30px;
		}
	}

	&--white {
		color: $white;
	}

  	&--black {
	  	color: $black;
	}

	&--reversed {
		flex-direction: row-reverse;

		.fancy__content {
			margin-right: auto;
			margin-left: 10px;
			padding-left: 0;
			padding-right: 10px;
		}
	}
}

/* === Tablet === */
@include media-breakpoint-down(md) {
	.fancy {
		&__content {
			width: 50%;
		}

		&__title {
			margin-bottom: 20px;
			font-size: 40px;
		}

		&__entry {
			font-size: 21px;
		}

		.fancy__front {
			font-size: 20px !important;
		}

		.fancy__back {
			font-size: 100px !important;
		}

		&__logos {
			margin-top: 20px;
		}

		.fancy__background {
			&--second {
				position: static;
				margin-top: -50px;
			}

			&--abs-mob-2 {
				margin-top: -100px;
			}

			&--abs-mob {
				margin-top: 0;
				margin-bottom: -50px;
			}
		}

		&__image {
			width: 50%;
		}

		&__actions {
			margin-top: 20px;
			.btn {
			  height: 58px;
			  max-width: 160px;
			}
		}

		&--reversed {
		  flex-direction: row-reverse;

		  .fancy__content {
			margin-right: auto;
			margin-left: 10px;
			padding-left: 0;
			padding-right: 10px;
		  }
		}
	}
}

/* === Mobile === */
@include media-breakpoint-down(sm) {
	.fancy {
		display: block;

		&__image {
			width: 100%;
			margin-top: 20px;
		}

		&__title {
		  	font-size: 48px;
			font-weight: 100;
		  	&__video {
			  font-size: 48px;
			  padding-bottom: 5px;
			  font-weight: 100;
			}
		}

		&__top {
			.youtube-ic {
				width: 47px;
			}
		  	&__video {
			  img {
				max-width: 95px;
			  }
			}
		}

		&__entry {
			font-size: 18px;

			a {
				font-size: 24px;
			  	text-transform: uppercase !important;
			}
		}

		&__actions {
			padding-bottom: 10px !important;
			padding-top: 10px !important;
			margin-bottom: 0 !important;

			.btn {
			  height: 45px;
			  min-width: 130px;
			  font-size: 18px;
			}
			&__video {
				padding-bottom: 10px !important;
				padding-top: 28px !important;
				margin-bottom: 0 !important;

				.btn {
				  height: 45px;
				  min-width: 130px;
				  font-size: 18px;
				}
			}
		}

		.fancy__background {
			position: relative;
			width: 100%;
			margin-top: 50px;

			&--first {
				right: -20px !important;
				top: auto !important;
			}

			&--second {
				position: static;
				margin-top: 20px;
			}

			&--abs-mob {
				position: absolute;
				left: 0px !important;
				top: 178px !important;
				right: auto !important;
			}

			&--abs-mob-2 {
				position: absolute;
				top: 150px !important;
				right: -15px !important;
			}
		}

		&__content {
			width: 100%;
			padding-left: 0;
		}
	}
}
