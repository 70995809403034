/* ------------------------------------------------------------ *\
	Product Main
\* ------------------------------------------------------------ */

.product-main {
	display: flex;

	&__images {
		width: 665px;

	  	.slider-nav {
		  .slick-list {
			.slick-track {
			  margin-left: 0;
			}
		  }
		}
	}

	&__content {
		flex: 1;
		padding-left: 23px;
	}

	&__logo {
		margin-bottom: 48px;
	  	max-width: 400px;
	}

	&__subtitle {
		margin-bottom: 50px;
		font-size: 20px;
		color: $yellow;
		text-transform: uppercase;
		font-weight: 300;
	}

	&__name {
		margin-bottom: 3px;
		font-size: 51px;
		line-height: 1;
		font-family: $secondary-font;
		font-weight: 500;
		letter-spacing: 0;
	}

	.details {
		padding: 34px 0;

		p {
			margin-bottom: 1.25em;
			font-size: 22px;
			line-height: 1.3;
			font-weight: 300;
		}
	  	ul {
			font-size: 22px;
			line-height: 1.3;
			font-weight: 300;
		  	padding-left: 22px;
		}
	}

	.downloads {
		padding: 30px 0;

		ul {
			padding-left: 0;
			list-style-type: none;
		}

		li + li {
			margin-top: 5px;
		}

		a {
			display: inline-flex;
			align-items: center;
			color: $black;
			font-size: 18px;
			font-weight: 400;

			img {
				margin-right: 6px;
			}

			&:hover {
				font-weight: 700;
			}
		}
	}

	.specifiactions {
		padding: 30px 0;

		table {
			width: 100%;
			margin: 0;

			th {
				border-top-color: $black;
				height: 27px;
				font-size: 22px;
				line-height: 1;
				text-transform: uppercase;
				vertical-align: middle;
			  	padding: 6px 0 4px 0;
			}

			tr.solid {
				background-color: #F6F6F6;
			}

			td {
				border-top-color: $black;
				height: 27px;
				font-size: 22px;
				line-height: 1;
				padding: 6px 0 4px 0;
				vertical-align: middle;
			}
		}
	}

	.list-features {
		display: flex;
		flex-wrap: wrap;
		padding-left: 0;
		padding-bottom: 48px;
		border-bottom: 2px solid #818181;
		margin-bottom: 25px;
		list-style-type: none;

		li {
			width: 50%;
			display: inline-flex;
			margin-bottom: 16px;
			font-size: 22px;
			line-height: 1;
			align-items: center;

			span {
				margin-left: 7px;
			  	margin-right: 7px;
			}
		}
	}
}

/* === Small Desktop === */
@include media-breakpoint-down(lg) {
	.product-main {
		display: block;

		&__images {
			width: 100%;
			margin-bottom: 30px;
		}

		&__content {
			padding-left: 0;
		}
	}
}

/* === Mobile === */
@include media-breakpoint-down(sm) {
	.product-main {

	  	&__logo {
			max-width: 190px;
		  	margin-bottom: 40px;
		}

		&__name {
			font-size: 56px;
		}

		&__subtitle {
			margin-bottom: 20px;
			font-size: 21px;
		}

		.list-features {
			padding-bottom: 10px;
			margin-bottom: 10px;

			li {
				font-size: 16px;
			}
		}

		.details {
			padding: 10px 0;
		}

		.details p {
			font-size: 16px;
		}

		.specifiactions {
			padding: 10px 0;

			table {
				th, td {
					font-size: 16px;
				}
			  	td {
				  vertical-align: top;
				  padding-right: 4px;
				}
			}
		}

		.downloads {
			padding: 10px 0;

			a {
				font-size: 16px;
			}
		}

	  	&__content {
		  .accordion {
			&__head {
			  h3 {
				font-size: 24px;
			  }
			}
			&__body {
			  .details {
				p {
				  font-size: 16px;
				}
				ul {
				  font-size: 16px;
				}
			  }
			}
		  }
		}
	}
}
