/* ------------------------------------------------------------ *\
	Hero Small
\* ------------------------------------------------------------ */

.hero-small {
	padding-top: 130px;

	&__title {
		max-width: 764px;
		padding: 26px 0;
		margin: 0 auto;
		background-color: $white;
		text-align: center;
		font-size: 51px;
		letter-spacing: -0.01em;
		font-weight: 400;
		text-transform: uppercase; 
	} 
}

/* === Mobile === */
@include media-breakpoint-down(sm) {
	.hero-small {
		padding-top: 50px;
		
		&__title {
			font-size: 28px;
		}
	}
}
