/* ------------------------------------------------------------ *\
	Header
\* ------------------------------------------------------------ */

.header {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	z-index: 5;
	background-color: $black;

	&__inner {
		position: relative;
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	&__actions {
		ul {
			display: flex;
			align-items: center;
			padding-left: 0;
			list-style-type: none;
		}

		li + li {
			margin-left: 25px;
		}
	}

	.lang {
		position: relative;

		& > a {
			display: block;
			padding: 42px 0;
		}

		.lang-dropdown {
			position: absolute;
			top: 100%;
			right: 0;
			width: 100px;
			z-index: 10;
			opacity: 0;
			visibility: hidden;
			background-color: #FFFFFF;

			ul {
				display: block;
				padding: 20px 0;
				list-style-type: none;

				li + li {
					margin-left: 0;
					margin-top: 22px;
				}

				a {
					display: flex;
					justify-content: space-between;
					align-items: center;
					padding: 0 15px;
					font-size: 16px;
					color: #000000;
					transition: all .4s;

					&:hover {
						background-color: #707070;
						color: #fff;
					}
				}
			}
		}

		&:hover .lang-dropdown {
			opacity: 1;
			visibility: visible;
		}
	}
}

/* === Small Desktop === */
@include media-breakpoint-down(lg) {
	.header {
		.lang {
			& > a {
				padding: 21px 0;
			}
		}
	}
}

/* === Tablet === */
@include media-breakpoint-down(md) {
	.header {
		.logo {
			width: 190px;
		}

		.lang {
			display: none;
		}

		.srch {
			img {
				width: 20px;
			}
		}

		&__inner {
			padding: 22px 22px;
		}

		&__content {
			position: fixed;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			padding: 92px 39px;
			overflow: auto;
			background-color: $yellow;
			z-index: 5;

			& > a {
				position: absolute;
				top: 10px;
				left: 40px;
			}
		}
	}
}
