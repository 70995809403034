/* ------------------------------------------------------------ *\
	Button
\* ------------------------------------------------------------ */

.btn {
	position: relative;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	text-decoration: none;
	white-space: normal;
	font-size: 24px;
	font-family: $secondary-font;
	font-weight: 500;
	text-transform: uppercase;
	border-radius: 0;
	height: 81px;
	min-width: 220px;

	&:focus,
	&.focus {
		box-shadow: none;
	}
}

.btn-primary {
	@include button-variant(#000000, $black, $yellow, $yellow, $yellow);

	color: $white;

	&:not(:disabled):not(.disabled):active,
	&:not(:disabled):not(.disabled).active,
	.show > &.dropdown-toggle {
		border-color: $yellow;

		&:focus {
			box-shadow: none;
		}
	}

	&:focus,
	&.focus {
		box-shadow: none;
	}
}

.btn-secondary {
	@include button-variant($white, $white, $yellow, $yellow, $yellow);

	color: $black;

	&:not(:disabled):not(.disabled):active,
	&:not(:disabled):not(.disabled).active,
	.show > &.dropdown-toggle {
		border-color: $yellow;

		&:focus {
			box-shadow: none;
		}
	}

	&:focus,
	&.focus {
		box-shadow: none;
	}
}

/* === Mobile === */
@include media-breakpoint-down(sm) {
	.btn {
		min-width: 200px;
		height: 55px;
	}
}
