/* ------------------------------------------------------------ *\
	FAQ
\* ------------------------------------------------------------ */

.faq {
	padding: 20px 0;

	img {
		margin-bottom: 20px;
	}

	p {
		margin-bottom: 20px;
		font-size: 22px;
		font-weight: 300;
		line-height: 1.09;
		letter-spacing: -0.01em; 
	}

	&__links {
		margin-bottom: 0 !important;
		padding-left: 0; 
		list-style-type: none;

		li + li {
			margin-top: 11px;
		}
	}

	&__link {
		color: #000;
		font-size: 20px;
		font-weight: 400;
		letter-spacing: 0;

		&:hover {
			color: #000;
			text-decoration: underline;
		} 

		svg {
			margin-left: 10px;
		}
	}
}
