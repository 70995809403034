/* ------------------------------------------------------------ *\
	Form Contacts
\* ------------------------------------------------------------ */

.form-contact {
	display: flex;

	&__content {
		width: 55%;
		padding-left: 97px;
	}

	&__fields {
		width: 45%;
	}

	.form {
		padding-right: 17px;
	}

	&__hint {
		margin-bottom: 17px;
		font-size: 22px;
		line-height: 1.18;
		font-weight: 300;
		letter-spacing: -0.01em; 
	}

	&__req {
		font-size: 22px;
		line-height: 1.18;
		font-weight: 300;
		letter-spacing: -0.01em;

		p {
			margin-bottom: 1.25em;
		}

		strong {
			font-weight: 400;
		} 
	}

	&__entry {
		font-size: 22px;
		line-height: 1.18;
		font-weight: 300;
		letter-spacing: -0.01em; 

		strong {
			font-weight: 700;
		}

		p {
			margin-bottom: 1.25em;
		}
	}
}

/* === Small Desktop === */
@include media-breakpoint-down(lg) {
	.form-contact {
		&__content {
			width: 35%;
			padding-left: 0;
		}

		&__fields {
			width: 65%;
			padding-right: 20px;
		}
	}
}

/* === Mobile === */
@include media-breakpoint-down(sm) {
	.form-contact {
		display: block;

		&__content {
			width: 100%;
			padding: 0;
		}

		&__fields {
			width: 100%;
			margin-bottom: 20px;
		}

		&__hint,
		&__entry,
		&__req {
			font-size: 18px;
		}
	}
}
