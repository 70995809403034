/* ------------------------------------------------------------ *\
	Base
\* ------------------------------------------------------------ */

body {
	font-family: $primary-font;
	font-size: 16px;
	font-weight: 300;
	color: $dark;
}

.loading {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;   
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: $white;
	z-index: 999;
}

ul:last-child,
ol:last-child,
p:last-child { 
	margin-bottom: 0; 
}

a:hover {
	text-decoration: none;
}

img {
	max-width: 100%;
	height: auto;
}

h2, .h2 {
	font-size: 84px;
	letter-spacing: -0.01em;
	line-height: 0.95;
	text-transform: uppercase;
	font-weight: 400;
}

h3, .h3 {
	font-size: 45px;
	font-family: $secondary-font;
	font-weight: 500;
	letter-spacing: 0;
	line-height: 1;  
}

/* === Mobile === */
@include media-breakpoint-down(sm) {
	h2, .h2 {
		font-size: 25px;
	}

	h3, .h3 {
		font-size: 21px;
	}
}
