/* ------------------------------------------------------------ *\
	Form Default
\* ------------------------------------------------------------ */

.form-default {
	display: flex;

	&__title {
		color: #000000;
	}

	&__content {
		width: 51%;
		padding-right: 117px;
	}

	&__subtitle {
		margin-bottom: 44px;
		color: $yellow;
		font-size: 44px; 
		line-height: 1.02;
		letter-spacing: -0.01em;
		text-transform: uppercase;
		font-family: $primary-font;  
	}

	&__fields {
		width: 49%;
		padding-right: 200px;
	}

	&__entry {
		font-size: 30px;
		line-height: 1;
		letter-spacing: -0.01em;
		font-weight: 300;
		color: #000000; 

		p {
			margin-bottom: 1em;
		}
	}
}

/* === Small Desktop === */
@include media-breakpoint-down(lg) {
	.form-default {
		&__fields {
			padding-right: 0;
		}
	}
}

/* === Tablet === */
@include media-breakpoint-down(md) {
	.form-default {
		display: block;

		&__content {
			width: 100%;
			padding-right: 0;
		}

		&__title {
			br {
				display: none;
			}
		}

		&__fields {
			width: 100%;
		}
	}
}

/* === Mobile === */
@include media-breakpoint-down(sm) {
	.form-default {
		&__subtitle {
			margin-bottom: 20px;
			font-size: 20px;
		}

		&__entry {
			font-size: 16px;
		}
	}
}
