/* ------------------------------------------------------------ *\
	Sidebar
\* ------------------------------------------------------------ */

.sidebar {
	&__nav {
		ul {
			padding-left: 0;
			font-size: 22px;
			font-weight: 300;
			list-style-type: none; 
		}

		a {
			color: $black;
			transition: color .4s;

			&.active,
			&:hover {
				color: #7E7D7D;
			}
		}

		li + li {
			margin-top: 5px;
		}
	}
}

/* === Tablet === */
@include media-breakpoint-down(md) {
	.sidebar {
		&__nav {
			ul {
				font-size: 18px;
			}
		}
	}
}

/* === Mobile === */
@include media-breakpoint-down(sm) {
	.sidebar {
		&__nav {
			display: none;
		}
	}
}
