/* ------------------------------------------------------------ *\
	Socials
\* ------------------------------------------------------------ */

.socials {
	ul {
		display: flex;
		padding-left: 0;
		margin: 0 -11px;
		list-style-type: none;
	}

	a {
		position: relative;
		display: flex;
		overflow: hidden;
		justify-content: center;
		align-items: center;
		min-height: 294px;
		background: rgba(255, 255, 255, 0.5);
		transition: background .4s;
	}

	&__overlay {
		position: absolute;
		left: 0;
		right: 0;
		bottom: 0;
		padding: 20px 25px;
		background: #000;
		color: #fff;
		height: 0;
		opacity: 0;
		visibility: hidden;
		transition: all .4s;

		img {
			position: absolute;
			bottom: 23px;
			right: 23px;
		}
	}

	@media(min-width: 1025px){
		a:hover {
			.socials__overlay {
				height: 100%;
				opacity: 1;
				visibility: visible;
			}
		}
	}

	li {
		width: 33.33%;
		padding: 0 11px;
	}
}

/* === Tablet === */
@include media-breakpoint-down(md) {
	.socials {
		&__overlay {
			display: none;
		}
	}
}

/* === Mobile === */
@include media-breakpoint-down(sm) {
	.socials {
		ul {
			display: block;
			margin: 0 0 0 0;
		}

		li {
			width: 146px;
			margin: 0 auto 20px;
			padding: 0;
		}

		a {
			min-height: 115px;

			img {
				width: 60px;
			}
		}
	}

  	.section {
	  &__foot {
		margin-left: 10px;
		margin-right: 10px;
	  }
	}
}
