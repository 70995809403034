/* ------------------------------------------------------------ *\
	Section
\* ------------------------------------------------------------ */

.section {
	position: relative;
	padding: 137px 0;

	&__head {
		margin-bottom: 68px;
		text-align: center;
	}

	&__foot {
		margin-top: 50px;
		text-align: center;
		font-size: 30px;
		line-height: 1;
		font-weight: 300;
	}

	&__bg {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background-size: cover;
		background-repeat: no-repeat;
		background-position: center center;
		z-index: -1;

		&:after {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			//background: rgba(255, 255, 255, 0.54);
		}

		&.linear-left:after {
			display: block !important;
			background: linear-gradient(to right, rgba($black, 1), transparent);
		}

		&.linear-right:after {
			display: block !important;
			background: linear-gradient(to left, rgba($black, 1), transparent);
		}

		&.linear-top:after {
			display: block !important;
			background: linear-gradient(to bottom, rgba($black, 1), transparent);
		}

		&.linear-bottom:after {
			display: block !important;
			background: linear-gradient(to top, rgba($black, 1), transparent);
		}

		&.linear-full:after {
			display: block !important;
			background: rgba($black, .4);
		}

		&.linear-radial:after {
			display: block !important;
			background: radial-gradient(transparent, rgba($black, 1));
		}
	}

	&--no-overlay {
		.section__bg:after {
			display: none;
		}
	}

	&--overflow {
		overflow: hidden;
	}

	&--small {
		padding: 53px 0;
	}

	&--full-screen {
		display: flex;
		align-items: center;
		min-height: 1080px;
	}

	&--updated {
		min-height: auto;
		//max-height: 1080px;
		padding: 0;

		.fancy .fancy__content {
			padding-top: 50px;
			padding-bottom: 50px;
		}
	}
}

/* === Tablet === */
@include media-breakpoint-down(md) {
	.section {
		padding: 50px 0;

		&__head {
			margin-bottom: 30px;
		}

		&__foot {
			margin-top: 30px;
			font-size: 16px;

			br {
				display: none;
			}
		}

		&--full-screen {
			min-height: auto;
		}

		&--updated {
			min-height: auto;
			max-height: none;
			padding: 75px;

			.fancy .fancy__content {
				padding-top: 0;
				padding-bottom: 0;
			}
		}
	}
}

/* === Mobile === */
@include media-breakpoint-down(sm) {
	.section {
		padding: 30px 0;

		&--no-center-mobile {
			display: block;
		}

		&--full-screen {
			min-height: 633px;
		}
	}
}
