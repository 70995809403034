/* ------------------------------------------------------------ *\
	Modal
\* ------------------------------------------------------------ */

.holder {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	overflow-y: auto;
	padding: 20px 0 !important;
	opacity: 0;
	visibility: hidden;
	background: rgba(0, 0, 0, 0.5);
	z-index: 6;

	&.show {
		opacity: 1;
		visibility: visible;
	}
}

.modal-backdrop {
	display: none;
}

/* === Mobile === */
@include media-breakpoint-down(sm) {
	.holder {
		background: $white;
	}
}
