/* ------------------------------------------------------------ *\
	Accordions
\* ------------------------------------------------------------ */

.accordion {
	&__head {
		position: relative;
		padding-right: 50px;

		img {
			position: absolute;
			top: 0;
			bottom: 0;
			right: 0;
			margin: auto;
		}

		h3 {
			margin-bottom: 0;
			font-size: 28px;
			line-height: 1;
			text-transform: uppercase;
		}
	}

	&__section {
		padding-bottom: 20px;
		border-bottom: 2px solid #818181;
		margin-bottom: 20px;
	}
}

/* === Mobile === */
@include media-breakpoint-down(sm) {
	.accordion {
		&__head {
			h3 {
				font-size: 18px;
			}
		}

		&__section {
			padding-bottom: 10px;
			margin-bottom: 10px;
		}
	}
}

/*  FAQ  */

.accordion-faq {
	&__section {
		padding-bottom: 12px;
		border-bottom: 1px solid #707070;
		margin-bottom: 12px;
	}

	&__head {
		h3 {
			margin-bottom: 0;
			font-size: 22px;
			font-weight: 400;
			line-height: 1.09;
			letter-spacing: -0.01em;
			font-family: $primary-font;
		}
	}
}

/* === Tablet === */
@include media-breakpoint-down(md) {
  .accordion {
	&__head {
	  h3 {
		font-size: 18px;
	  }
	}

  }
}
