/* ------------------------------------------------------------ *\
	Flex container
\* ------------------------------------------------------------ */

.flex-container {
	display: flex;

	.sidebar {
		width: 253px;
	}

	.content {
		flex: 1;
		padding-left: 40px;
	}
}

/* === Tablet === */
@include media-breakpoint-down(md) {
	.flex-container {
		display: flex;

		.sidebar {
			margin-bottom: 20px;
		}

		.content {
			padding-left: 0;
		}
	}
}
