/* ------------------------------------------------------------ *\
	Category
\* ------------------------------------------------------------ */

.categories {
	display: flex;
	flex-wrap: wrap;
	margin: 0 -10px -42px;

	.category {
		width: 50%;
		padding: 0 10px;
		margin-bottom: 42px;
	}
}

.category {
	&__content {
		position: relative;
		//padding: 10px 0;
	  	padding: 5px 5px 14px;
		font-size: 31px;
		letter-spacing: -0.01em;
		transition: background .4s;
		text-align: center;
		font-weight: 400;

		span {
			position: absolute;
			left: 0;
			right: 0;
			top: 0;
			height: 0%;
			transition: height .4s;
		}

		p {
			position: relative;
			z-index: 2;
			line-height: 1;

			br {
				display: none;
			}
		}
	}

	&__inner {
		color: $dark;
		transition: color .4s;
	}

	@media(min-width: 1025px){
		&__inner:hover {
			text-decoration: none;
			color: $white;

			.category__image:before {
				height: 100%;
			}

			span {
				height: 100%;
			}
		}
	}


	&__image {
		position: relative;
		border-bottom: 14px solid transparent;

		&:before {
			content: '';
			position: absolute;
			bottom: 0;
			left: 0;
			right: 0;
			background: rgba(0, 0, 0, 0.5);
			height: 0;
			transition: all .4s;
		}

		&:after {
			content: '';
			display: block;
			padding-top: 42.68%;
		}
	}
}

/* === Tablet === */
@include media-breakpoint-down(md) {
	.categories {
		margin: 0 -5px -12px;

		.category {
			padding: 0 5px;
			margin-bottom: 12px;
		}
	}

	.category {
		&__image {
			border-bottom: 0;

			&:after {
				padding-top: 126.54%;
			}
		}

		&__content {
			padding: 5px 0;
			font-size: 16px;
			background-color: $blue;
			color: $white;

			p {
				br {
					display: block;
				}
			}
		}

		&--teal {
			.category__content {
				background-color: $teal;
			}
		}

		&--red {
			.category__content {
				background-color: $red;
			}
		}

		&--green {
			.category__content {
				background-color: $green;
			}
		}
	}
}

/* === Mobile === */
@include media-breakpoint-down(sm) {
	.category {
		&__content {
			background: none !important;
		  	min-height: 50px;
		  	span {
			  width: 100%;
			  height: 100%;
			}
		  	p {
			  padding: 4px 2px 0px 2px;
			}
		}
	}
}
