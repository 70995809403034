/* ------------------------------------------------------------ *\
	Page Entry
\* ------------------------------------------------------------ */

.page-entry {
	max-width: 764px;
	margin: 0 auto;
	font-size: 30px;
	line-height: 1;
	text-align: center;
}

/* === Mobile === */
@include media-breakpoint-down(sm) {
	.page-entry {
		font-size: 18px;
	}
}
