/* ------------------------------------------------------------ *\
	News Item
\* ------------------------------------------------------------ */

.news-item {
	display: flex;
	align-items: center;

	&__image {
		width: 369px;

		&:after {
			content: '';
			display: block;
			padding-top: 78.32%; 
		}
	}

	&__content {
		flex: 1;
		padding-left: 20px;
	}

	&__title {
		text-transform: uppercase;
	}

	&__entry {
		margin-top: 25px;
		font-family: $secondary-font;
		font-size: 22px;
		line-height: 1.18;
		font-weight: 400; 
	}

	&__link {
		margin-top: 25px;
	}

	&__link a {
		color: #000000;
		transition: color .4s;
		font-family: $secondary-font;
		font-size: 22px;
		line-height: 1.18;
		font-weight: 500; 

		&:hover {
			color: #7E7D7D;
		}
	}
}

.news-item + .news-item {
	padding-top: 50px;
	border-top: 1px solid #707070;
	margin-top: 50px; 
}

/* === Small Desktop === */
@include media-breakpoint-down(lg) {
	.news-item {
		align-items: flex-start;

		&__image {
			width: 250px;
		}
	}
}

/* === Tablet === */
@include media-breakpoint-down(md) {
	.news-item {
		&__image {
			width: 369px; 
		}
	}
}

/* === Mobile === */
@include media-breakpoint-down(sm) {
	.news-item {
		display: block;

		&__image {
			width: 100%;

			&:after {
				padding-top: 56.25%;
			}
		}

		&__content {
			padding-left: 0;
			margin-top: 20px;
		}

		&__entry {
			margin-top: 15px;
		}

		&__title br {
			display: none;
		}

		&__link {
			margin-top: 15px;
		}

		&__entry,
		&__link a {
			font-size: 18px;
		}
	}

	.news-item + .news-item {
		padding-top: 20px;
		margin-top: 20px;
	}
}
