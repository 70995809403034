/* ------------------------------------------------------------ *\
	List Media
\* ------------------------------------------------------------ */

.list-media {
	position: relative;

	& > .close {
		position: absolute;
		top: 60px;
		right: 130px;
		cursor: pointer;
	}

	&__item img {
		width: 100%;
	}

	&__item + &__item {
		margin-top: 58px;
	}

	&__item--video {
		position: relative;

		&:after {
			display: block;
			padding-top: 56.25%;
			content: '';
		}

		iframe {
			@include position-iframe;
		}
	}
}

/* === Mobile === */
@include media-breakpoint-down(sm) {
	.list-media {
		& > .close {
			top: 20px;
			right: 20px;
		}
	}
}
