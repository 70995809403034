/* ------------------------------------------------------------ *\
	Article
\* ------------------------------------------------------------ */

.article {
	&__title {
		margin-bottom: 30px;
	}

	p, ul {
		font-size: 22px;
		line-height: 1.09;
		letter-spacing: -0.01em;
	}

	ul {
		padding-left: 0;
		margin-bottom: 1.88em;
		list-style-position: inside;
	}

	h4 {
		margin-top: 60px;
		margin-bottom: 25px;
		font-size: 36px;
		font-weight: 400;
		letter-spacing: 0.01em;
		line-height: 1.11;
	}

	&__links {
		padding-left: 0;
		list-style-type: none;

		li + li {
			margin-top: 11px;
		}
	}

	&__link {
		color: #000;
		font-size: 20px;
		font-weight: 400;
		letter-spacing: 0;

		&:hover {
			color: #000;
			text-decoration: underline;
		}

		svg {
			margin-left: 10px;
		}
	}
}

ul#user-manuals {
	li {
	  padding-bottom: 10px !important;
	}
}

/* === Tablet === */
@include media-breakpoint-down(md) {
	.article {
		p, ul {
			font-size: 16px;
		}

		h4 {
			margin-top: 20px;
			font-size: 24px;
			margin-bottom: 20px;
		}

	  	&__link {
		  	font-size: 18px;
		}
	}
}
