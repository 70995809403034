/* ------------------------------------------------------------ *\
	List Categories
\* ------------------------------------------------------------ */

.list-categories {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	align-items: center;
	padding-left: 0;
	margin-bottom: -30px;  
	list-style-type: none;
	font-size: 22px;
	letter-spacing: -0.01em; 

	a {
		position: relative;
		padding-bottom: 10px;
		border-bottom: 3px solid transparent; 
		color: #000000;
		transition: color .4s;

		&:hover {
			color: #7E7D7D;
		}
	}

	li {
		margin-bottom: 30px;
		line-height: 1;
	}

	li + li {
		padding-left: 10px;
		border-left: 1px solid #000;
		margin-left: 10px;
	}
}

/* === Mobile === */
@include media-breakpoint-down(sm) {
	.list-categories {
		display: block;
		text-align: center;

		li + li {
			padding-left: 0;
			border: 0;
			margin-left: 0;
		}
	}
}
