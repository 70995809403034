/* ------------------------------------------------------------ *\
	Hero
\* ------------------------------------------------------------ */

.hero-slider {

  	&__logo {
		max-width: 250px;
		padding-bottom: 45px;
	}
	&__slide {
		position: relative;

		&__bg {
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			background-size: cover;
			background-repeat: no-repeat;
			background-position: center center;

			&:after {
				content: '';
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
				z-index: 1;
			}

			&.linear-left:after {
				display: block !important;
				background: linear-gradient(to right, rgba($black, 1), transparent);
			}

			&.linear-right:after {
				display: block !important;
				background: linear-gradient(to left, rgba($black, 1), transparent);
			}

			&.linear-top:after {
				display: block !important;
				background: linear-gradient(to bottom, rgba($black, 1), transparent);
			}

			&.linear-bottom:after {
				display: block !important;
				background: linear-gradient(to top, rgba($black, 1), transparent);
			}

			&.linear-full:after {
				display: block !important;
				background: rgba($black, .4);
			}

			&.linear-radial:after {
				display: block !important;
				background: radial-gradient(transparent, rgba($black, 1));
			}
		}
	}

	&__image {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
	}

	&__content {
		position: relative;
		z-index: 20;
		//padding: 100px 0 0;
		height: calc(100vh - 105px);

		.container {
			display: flex;
			flex-direction: row-reverse;
			align-items: flex-end;

		  	height: 100%;
		}

		&-inner {
			width: 50%;
			flex: 1;
			//padding-bottom: 100px;
		  	margin: auto;
		}

		.text-center {
			.hero-slider__logo {
				margin: 0 auto;
			}
		}

		&.reversed .container {
			flex-direction: row;

			.hero-slider__decoration {
				padding-right: 10px;
				padding-left: 0;
			}
		}
	}

	&__title {
		font-size: 88px;
		line-height: 0.9;
		text-transform: uppercase;
		color: $white;
	  	font-weight: 100;
	}

	&__decoration {
		width: 50%;
		padding-left: 50px;
		z-index: 1;
	}

	&__subtitle {
		color: $yellow;
		font-size: 36px;
		line-height: 1;
		letter-spacing: -0.01em;
	  	font-weight: 300;
	}

	&__actions {
		margin-bottom: 50px;
	  	padding-top: 35px;

	  	.btn {
		  height: 58px;
		  max-width: 160px;
		}
	}

	.dots {
		position: absolute;
		bottom: 65px;
		left: 0;
		right: 0;
		z-index: 5;
		text-align: center;

		.swiper-pagination-bullet {
			width: 19px;
			height: 19px;
			margin: 0 10px;
			background-color: $white;
			opacity: 1;

			&-active {
				background-color: $yellow;
			}
		}
	}
}

/* === Small Desktop === */
@include media-breakpoint-down(lg) {
	.hero-slider {
		.dots {
			bottom: 30px;
		}

		&__title {
			font-size: 80px;
		}
	}
}

/* == Tablet Adjustments == */
@include media-breakpoint-down(md) {
  	.hero-slider {
	  &__decoration {
		width: 100%;
		padding-left: 0px !important;
	  }
	}

}

/* === Mobile === */
@include media-breakpoint-down(sm) {
	.hero-slider {
		&__content {
			display: block;
			height: calc(100vh - 74px);
			padding: 50px 0 0;

			.container {
				flex-wrap: wrap;
			}

			&-inner {
				width: 100%;
				padding-bottom: 0;

				&:only-child {
					padding-bottom: 50px;
				}
			}
		}

		&__logo {
			padding-bottom: 45px;

			img {
				max-width: 60%;
			}
		}

		&__decoration {
			width: 100%;
			text-align: center;
			padding: 0;
			order: 2;

			img {
				max-width: 75%;
			  	max-height: 400px;
			}
		}

		&__title {
			font-size: 48px;
			letter-spacing: -0.01em;
			line-height: 0.92;
		}

		.dots {
			.swiper-pagination-bullet {
				width: 10px;
				height: 10px;
			}
		}

		&__subtitle {
			font-size: 24px;
			line-height: 1.1;
		  	font-weight: 300 !important;
		}

	  	&__actions {
		  padding-bottom: 10px !important;
		  padding-top: 28px !important;
		  margin-bottom: 0 !important;
		  .btn {
			height: 45px;
			min-width: 130px;
			font-size: 18px;
		  }
		}
	}
}

// -- Fixing hero image size for silly laptops 1366 x 768 -- //
@media (min-width: 1400px) {
  .hero-slider {
	&__decoration {
	  img {
		height: 90vh !important;
		width: auto !important;
	  }
	}
  }
}
