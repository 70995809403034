/* ------------------------------------------------------------ *\
	Search
\* ------------------------------------------------------------ */

.search {
	&__title {
		margin-bottom: 50px;
		text-transform: uppercase;

		span {
			color: $yellow;
		}
	}
}

/* === Mobile === */
@include media-breakpoint-down(sm) {
	.search {
		&__title {
			margin-bottom: 20px;
		}
	}
}
