/* ------------------------------------------------------------ *\
	Footer
\* ------------------------------------------------------------ */

.footer {

  	left: 0;
  	right: 0;
  	bottom: 0;
	padding: 63px 0;
	background: $black;
	color: $white;

	&__logo {
		margin-bottom: 47px;
	}

	&__nav {
		ul {
			padding-left: 0;
			list-style: none;
			font-size: 22px;
			line-height: 1.2;
			letter-spacing: -0.01em;
			font-weight: 300;
		}

		a {
			color: $white;

			&:hover {
				text-decoration: underline;
			}
		}

		li + li {
			margin-top: 10px;
		}
	}

	&__actions {
		display: flex;
	}

	&__socials {
		width: 50%;

		ul {
			padding-left: 0;
			list-style: none;
		}

		li + li {
			margin-top: 18px;
		}

		a {
			transition: opacity .4s;
		}

		a:hover {
			opacity: .8;
		}
	}

	&__logos {
		width: 50%;
		margin-top: -50px;
	  	margin-bottom: 30px;
	}

	&__title {
		margin-bottom: 20px;
		font-size: 26px;
		text-transform: uppercase;
		font-weight: 700;
	}
	&__creds {

	  padding-right: 30px;
	  padding-top: 20px;
	  a {
		text-decoration: none;
		color: white;
	  }
	  a:hover {
		text-decoration: underline;
	  }
	  .left {
		float: left;
		width: 50%;
		text-align: left;
	  }
	  .right {
		float: right;
		width:50%;
		text-align: right;
	  }
	}
}

/* === Tablet === */
@include media-breakpoint-down(md) {
	.footer {
		.col-md-6 {
			margin-bottom: 20px;
		}
	}
}

/* === Mobile === */
@include media-breakpoint-down(sm) {
	.footer {
		padding: 30px 0;

		&__logo {
			display: none;
		}

		&__title {
			font-size: 26px;
		  	margin-bottom: 10px;
		}

		&__logos {
			display: none;
		}

		&__socials {
			width: 100%;

			ul {
				display: flex;
				align-items: center;
			}

			li + li {
				margin: 0 0 0 20px;
			}
		}

		&__nav {
			font-size: 22px;
		}

	  	.col-md-6 {
		  margin-bottom: 40px;
		}


	}
}
