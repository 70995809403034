/* ------------------------------------------------------------ *\
	Field Upload
\* ------------------------------------------------------------ */

.field-upload {
	display: flex;
	align-items: center;
	height: 52px;
	padding: 0 17px;
	background: rgba(10, 17, 25, 0.05);

	input {
		position: absolute; 
		left: -9999px;
		opacity: 0;
	}

	&__label {
		display: inline-flex;
		align-items: center;
		margin-bottom: 0;
		cursor: pointer;
	}

	.btn {
		padding: 0 5px;
		height: 27px;
		min-width: auto;
		font-size: 22px;
		font-weight: 300;
		font-family: $primary-font;
		text-transform: none;
		flex-shrink: 0; 
	}

	&__text {
		margin-left: 25px;
		font-size: 22px; 
		font-weight: 300;
		color: #707070;
		text-overflow: ellipsis;
		overflow: hidden;
		white-space: nowrap;
	}
}

/* === Mobile === */
@include media-breakpoint-down(sm) {
	.field-upload {
		height: 40px;

		.btn {
			height: 25px;
			font-size: 16px;
		}

		&__text {
			font-size: 16px;
		}
	}
}
