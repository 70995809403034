/* ------------------------------------------------------------ *\
	Nav Lang
\* ------------------------------------------------------------ */

.nav-lang {
	margin-top: 65px;
	
	p {
		margin-bottom: 0;
		font-size: 20px;
		line-height: 1.2;
		text-transform: uppercase;
		color: $black;
	}

	ul {
		display: flex;
		align-items: center;
		padding-left: 0;
		margin-top: 16px;
		list-style-type: none;

		li + li {
			margin-left: 25px;
		} 
	}
}
