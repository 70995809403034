/* ------------------------------------------------------------ *\
	Search Bar
\* ------------------------------------------------------------ */

.search-bar {
	form {
		position: relative;
	}

	input {
		width: 100%;
		padding: 16px 0;
		border: 0;
		font-size: 39px;
		font-weight: 500;
		text-transform: uppercase;
		color: #D1D1D1;

		&::placeholder {
			color: #d1d1d1;
		}
	}

	a {
		position: absolute;
		top: 0;
		bottom: 0;
		right: 0;
		width: 28px;
		height: 28px;
		margin: auto;
	}
}

/* === Tablet === */
@include media-breakpoint-down(md) {
	.search-bar {
		input {
			font-size: 23px;
		}
	}
}
