/* ------------------------------------------------------------ *\
	Variables
\* ------------------------------------------------------------ */

$primary-font: "din-condensed", sans-serif;
$secondary-font: 'DIN Pro', sans-serif;

/* ------------------------------------------------------------ *\
	Bootstrap Variables
\* ------------------------------------------------------------ */

/*  Fonts  */
$font-family-sans-serif: $primary-font;

$line-height-base: 1.88;

/*  Paragraphs  */
$paragraph-margin-bottom: 1.88em;

/*  Container  */
$container-max-widths: (
	sm: 540px,
	md: 720px,
	lg: 960px,
	xl: 1186px
);

/*  Grid Breakpoints  */
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 1025px,
  xl: 1281px,
  dell-l: 1300px,
  dell-u: 1500px
) !default;

$teal: #2bb6aa;
$yellow: #fbb800;
$beige: #d7a932;
$red: #da291c;
$green: #96c11f;
$blue: #189fdb;
$dark: #0a1119;
