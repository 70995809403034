/* ------------------------------------------------------------ *\
	Article Main
\* ------------------------------------------------------------ */

.article-main {
	&__title {
		margin-bottom: 122px;
		font-family: $secondary-font;
		font-weight: 400; 
	}

	&__entry {
		columns: 3;
		column-gap: 16px;
		font-size: 22px;
		font-weight: 300;
		line-height: 1.18;
		letter-spacing: -0.01em;

		p {
			margin-bottom: 1.25em;
		}

		img {
			margin-bottom: 1.25em;
		}
	}

	&__socials {
		ul {
			display: flex;
			align-items: center;
			padding-left: 0;
			list-style-type: none; 
		}

		li + li {
			margin-left: 20px;
		}

		img {
			margin-bottom: 0;
		}

		a {
			transition: opacity .4s;
		}

		a:hover {
			opacity: .75;
		}
	}
}

/* === Tablet === */
@include media-breakpoint-down(md) {
	.article-main {
		&__title {
			margin-bottom: 30px;
		}

		&__entry {
			columns: 2;
		}
	}
}

/* === Mobile === */
@include media-breakpoint-down(sm) {
	.article-main {
		&__title {
			margin-bottom: 20px;
		}
		
		&__entry {
			columns: 1;
			font-size: 16px;
		}
	}
}
