/* ------------------------------------------------------------ *\
	Load
\* ------------------------------------------------------------ */

.load {
	margin-top: 90px;
	text-align: center;
}

/* === Mobile === */
@include media-breakpoint-down(sm) {
	.load {
		margin-top: 30px;
	}
}
