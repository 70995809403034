/* ------------------------------------------------------------ *\
	Breadcrumbs
\* ------------------------------------------------------------ */

.breadcrumbs {
	margin-bottom: 40px;
	font-size: 18px;
	letter-spacing: -0.01em;
	font-weight: 300;

	ul {
		display: flex;
		align-items: center;
		padding-left: 0;
		list-style-type: none;
	}

	li + li {
		padding-left: 10px;
		border-left: 1px solid #000;
		margin-left: 10px;
	}

	li {
		line-height: 1;
	}

	a {
		color: #000000;
	}
}

/* === Mobile === */
@include media-breakpoint-down(sm) {
	.breadcrumbs {
		font-size: 16px;

		ul {
			display: inline-block;
		}

		li {
			display: inline;
		}
	}
}

/* === Tablet === */
@include media-breakpoint-down(md) {
  .breadcrumbs {
	font-size: 14px;

	ul {
	  display: inline-block;
	}

	li {
	  display: inline;
	}
  }
}
