/* ------------------------------------------------------------ *\
	Slider
\* ------------------------------------------------------------ */

.slider-for {
	&__image {
		width: 100%;

		&:after {
			content: '';
			display: block;
			padding-top: 100%;
		}
	}
}

.slider-nav {
	margin-top: 20px;

	.slick-arrow {
		position: absolute;
		border: 0;
		background-color: transparent;
		font-size: 0;
		line-height: 0;
		width: 23px;
		height: 14px;
		z-index: 20;

		&:after {
			display: block; 
			content: '';
			width: 23px;
			height: 14px;
			background-image: url(../../assets/images/temp/slider-arrow.svg);
			background-size: 23px 14px;
			background-position: center center;
		}

		&:focus {
			outline: 0;
		}
	}

	.slick-disabled {
		opacity: .5;
		cursor: not-allowed;
	}

	.slick-next {
		top: 0;
		right: 20px;
		bottom: 0;
		margin: auto;
	}

	.slick-prev {
		top: 0;
		left: 20px;
		bottom: 0;
		margin: auto;
		transform: rotate(180deg);
	}

	.slick-list {
		margin: 0 -8px;
	}

	&__holder {
		padding: 0 8px;
	}

	&__image {
		&:after {
			content: '';
			display: block;
			padding-top: 100%;
		}
	}
}
