/* ------------------------------------------------------------ *\
	Form Elements
\* ------------------------------------------------------------ */

.field {
	width: 100%;
	height: 53px;
	padding: 0 19px;
	border: 0;
	font-size: 22px;
	letter-spacing: -0.01em;
	font-weight: 300;
	color: #707070; 
	background: rgba(10, 17, 25, 0.05);

	&--textarea {
		padding: 19px;
		height: 186px;
		resize: none;
		vertical-align: top;
	}
}

.select {
	width: 100%;
	height: 53px;
	padding: 0 19px;
	border: 0;
	font-size: 22px;
	letter-spacing: -0.01em;
	font-weight: 300;
	color: #707070; 
	background: rgba(10, 17, 25, 0.05);
	appearance: none;
}

.select-container {
	position: relative;

	svg {
		position: absolute;
		top: 0;
		right: 18px;
		bottom: 0;
		margin: auto;
		pointer-events: none;
	}
}

.form {
	&__row {
		margin-bottom: 15px;
	}
}

.checkbox {
	position: relative;
	margin: 35px 0;

	input {
		position: absolute;
		z-index: -1;
		opacity: 0;
	}

	label {
		position: relative;
		padding-left: 66px;
		margin: 0;
		font-size: 22px;
		line-height: 1.09;
		font-weight: 300;
		letter-spacing: -0.01em;

		a {
			text-decoration: underline;
			color: #000000;
		}

		&:before,
		&:after {
			content: '';
			position: absolute;
			transition: .3s;
		}

		&:before {
			top: 0;
			left: 0;
			width: 53px;
			height: 53px;
			background: rgba(10, 17, 25, 0.05);
		}
	}

	input:checked + label {
		&:before {
			background: $yellow;
		}
	}
}

/* === Mobile === */
@include media-breakpoint-down(sm) {
	.field {
		height: 40px;
		font-size: 16px;

		&--textarea {
			height: 100px;
		}
	}

	.select {
		height: 40px;
		font-size: 16px;
	}

	.checkbox {
		label {
			padding-left: 45px;
			font-size: 16px;
		}

		label:before {
			width: 30px;
			height: 30px;
		}
	}
}
