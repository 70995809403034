/* ------------------------------------------------------------ *\
	Product
\* ------------------------------------------------------------ */

.products {
	display: flex;
	flex-wrap: wrap;
	margin: 0 -10px -40px;

	.product {
		width: 33.33%;
		padding: 0 10px;
		margin-bottom: 40px;

	  	&__inner {
		  picture {
			background-color: #f6f6f6;
		  }
		}
	}
}

.product {
	@media(min-width: 1025px){
		&__inner:hover {
			.product__image:before {
				opacity: 1;
				visibility: visible;
			}
		}
	}

	&__image {
		&:after {
			display: block;
			padding-top: 100%;
			content: '';
		}

		&:before {
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			background: rgba(0, 0, 0, 0.5);
			opacity: 0;
			visibility: hidden;
			transition: all .4s;
			content: '';
		}
	}

	&__name {
		margin-top: 5px;
		line-height: 1.2;
		color: #000;
		font-size: 22px;
		letter-spacing: -0.01em;
	}
}

/* === Small Desktop === */
@include media-breakpoint-down(lg) {
	.products {
		.product {
			width: 50%;
		}
	}
}

/* === Tablet === */
@include media-breakpoint-down(md) {

	.flex-container {
	  display: flex !important;
	}
	.products {
		.product {
			width: 50%;
		}
	}
}

/* === Mobile === */
@include media-breakpoint-down(sm) {
	.products {
		margin-bottom: -20px;

		.product {
			width: 50%;
			margin-bottom: 20px;

		  	&__name {
			  font-size: 17px;
			}
		}
	}
}
