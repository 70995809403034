/* ------------------------------------------------------------ *\
	Box
\* ------------------------------------------------------------ */

.boxes {
	&__title {
		margin-bottom: 43px;
		font-family: $secondary-font; 
	}

	&__body {
		display: flex;
		flex-wrap: wrap;
		margin: 0 -10px -20px;
	}

	.box {
		width: 33.33%;
		padding: 0 10px;
		margin-bottom: 20px;
	}
}

.box {
	&__image {
		&:after {
			content: '';
			display: block; 
			padding-top: 78.49%; 
		}
	}

	&__content {
		margin-top: 20px;
		font-size: 28px;
		font-weight: 300;
		letter-spacing: -0.01em;
		line-height: 1; 
	}

	&__link {
		margin-top: 25px;
		
		a {
			font-weight: 400;
			font-size: 25px;
			letter-spacing: -0.01em;
			color: #000000;
			transition: color .4s;

			&:hover {
				color: #7E7D7D;
			}
		} 
	}
}

/* === Tablet === */
@include media-breakpoint-down(md) {
	.boxes {
		.box {
			width: 50%;
		}
	}
}

/* === Mobile === */
@include media-breakpoint-down(sm) {
	.boxes {
		.box {
			width: 100%;
		}
	}

	.box {
		&__content {
			font-size: 16px;
		}

		&__link {
			margin-top: 15px;

			a {
				font-size: 16px;
			}
		}
	}
}
