/* ------------------------------------------------------------ *\
	Video
\* ------------------------------------------------------------ */

.videos {
	display: flex;
	flex-wrap: wrap;
	margin: 0 -12px -27px;

	.video {
		width: 50%;
		padding: 0 12px;
		margin-bottom: 27px;
	}
}

.video {
	&__player {
		position: relative;

		&:after {
			display: block;
			padding-top: 56.25%;
			content: '';
		} 

		iframe {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
		}
	}
}

/* === Small Desktop === */
@include media-breakpoint-down(lg) {
	.videos {
		.video {
			width: 100%;
		}
	}
}

/* === Tablet === */
@include media-breakpoint-down(md) {
	.videos {
		.video {
			width: 50%;
		}
	}
}

/* === Mobile === */
@include media-breakpoint-down(sm) {
	.videos {
		.video {
			width: 100%;
		}
	}
}
